<template>
  <button class="scroll-top" @click="scrollTop">
    TOP
  </button>
</template>

<script>
export default {
  methods: {
    scrollTop () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style scoped>
.scroll-top {
  @apply rounded-full bg-white text-center flex flex-col items-center justify-center;
  width: 50px;
  height: 50px;
  color: #30ADFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.scroll-top::before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 16px 15px;
  border-color: transparent transparent #30ADFF transparent;
  content: '';
  display: inline-block;
}
</style>
