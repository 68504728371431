<template>
  <main>
    <header>
      <div class="main-top">
        <h1>
          <template v-if="!expanded">町田啓太遊樂園</template>
        </h1>
        <div class="toggle-icon" :class="{ 'expanded': expanded }" @click="expanded = !expanded">
          <div class="line"></div>
        </div>
      </div>
      <nav v-show="expanded" :class="{ 'expanded': expanded }">
        <ul>
          <li v-for=" item in menu" :key="item">
            <a :href="item.link" target="_blank">{{ item.title }}</a>
          </li>
        </ul>
      </nav>
    </header>
    <div class="container">
      <h1 class="text-2xl">
        {{ title }}
      </h1>
      <router-view />
    </div>
    <footer>
      <!-- TODO: 粉專 -->
      <div class="m-auto">
        <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprince.keita.machida%2F&tabs=timeline&width=359&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="359" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      <div class="copyright">
        <p>Copyright © 町田啓太今天營業了嗎 2021</p>
        <p>All Rights Reserved.</p>
      </div>
    </footer>
    <div class="action">
      <ScrollTop />
    </div>
  </main>
</template>

<script>
import ScrollTop from '@/components/ScrollTop'
export default {
  components: {
    ScrollTop
  },
  data () {
    return {
      menu: [
        { title: '2021 町田啓太生日應援活動', link: 'https://pse.is/3qfbcx' },
        { title: '町田啓太今天營業了嗎', link: 'https://pse.is/3r8x34' },
        { title: '七夕情人節妳的命運', link: 'https://pse.is/3qumcq' },
        { title: '新學期妳的命運 秋季日劇篇', link: 'https://pse.is/3qumcm' },
        { title: '新學期妳的命運 劇團 EXILE 篇', link: 'https://pse.is/3mrppy' },
        { title: '新學期妳的命運 町田啓太篇', link: 'https://pse.is/3nm7aj' }
      ],
      theme: 'default',
      expanded: false
    }
  },
  computed: {
    title () {
      let title = '新學期妳的命運！'
      switch (this.theme) {
        case 'drama':
          title += '秋季日劇篇'
          break
        case 'exile':
          title += '劇團 EXILE 篇'
          break
        case 'default':
        default:
          title += '町田啓太篇'
          break
      }
      return title
    }
  },
  beforeRouteEnter (to, from, next) {
    const body = document.getElementsByTagName('body')[0]
    let theme = 'default'
    let name = to.name
    name = name.toLowerCase()
    switch (name) {
      case 'home':
        theme = 'default'
        break
      default:
        theme = name
        break
    }
    body.dataset.theme = theme
    next((vm) => {
      vm.theme = theme
    })
  }
}
</script>

<style scoped>
main {
  @apply relative flex flex-col;
}
header {
  font-size: 20px;
  background-color: #604722;
}
.main-top {
  @apply w-full text-white flex justify-between items-center;
  padding: 16px 20px;
}
.main-top h1 {
  min-height: 30px;
}
.toggle-icon {
  @apply justify-self-end cursor-pointer;
  width: 20px;
  height: 20px;
  position: relative;
  right: 0;
}
.toggle-icon {
  @apply flex flex-col justify-between;
}
.toggle-icon .line,
.toggle-icon::before,
.toggle-icon::after {
  @apply rounded bg-white;
  width: 100%;
  height: 4px;
}
.toggle-icon::before,
.toggle-icon::after {
  content: '';
  display: inline-block;
  transition: transform linear .3s;
}
.toggle-icon.expanded .line {
  display: none;
}
.toggle-icon.expanded::before {
  transform-origin: center;
  transform: translate(0%, 200%) rotate(45deg);
}
.toggle-icon.expanded::after {
  transform-origin: center;
  transform: translate(0%, -200%) rotate(-45deg);
}
nav {
  width: 100%;
  background-color: #604722;
  position: absolute;
  left: 0;
  z-index: 999;
  transform: translateY(-100%);
  transition: all linear 1s;
}
nav.expanded {
  transform: translateY(0%);
}
nav a {
  @apply text-white block;
  background-color: #604722;
  padding: 16px 20px;
  transition: all linear .3s;
}
nav a:hover,
nav a:focus {
  background-color: #493312;
}
.container {
  @apply flex-grow;
  background: url('../assets/images/bg_top.png') top center no-repeat;
  background-size: contain;
  padding: 40px 0;
}
.container h1 {
  @apply font-bold text-center leading-6;
  margin-bottom: 12px;
  text-shadow: 2px 2px 0 var(--color-white), -2px -2px 0 var(--color-white), 1px -1px 0 var(--color-white), -1px 1px 0 var(--color-white);
}
footer {
  @apply text-center grid;
  margin-bottom: 20px;
  row-gap: 20px;
}
.action {
  position: fixed;
  bottom: 16px;
  right: calc(((100vw - 375px) / 2) + 16px);
}
</style>
